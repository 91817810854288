import _merge from 'lodash/merge'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    validator: {
      type: Object,
      required: true
    },

    currentStep: {
      type: Number,
      default: 0
    },

    totalSteps: {
      type: Number,
      default: 0
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    validate () {
      this.validator.$touch()

      if (this.validator.$error) {
        this.$notify.warning(this.$t('validations.form_error'))

        return false
      }

      this.validator.$reset()

      return true
    },

    /**
     * Proceeds to the next step by validating first
     * Calls goNext if valid
     */
    proceed () {
      if (this.validate()) {
        this.goNext()
      }
    },

    goNext () {
      this.$emit('next')
    },

    goPrev () {
      this.$emit('prev')
    },

    _sendToParent (data) {
      this.$emit('input', _merge({}, this.value, data))
    }
  }
}

export function createComputedFormField (prop) {
  return {
    get () {
      return this.value[prop]
    },

    set (value) {
      this._sendToParent({ [prop]: value })
    }
  }
}
