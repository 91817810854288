<template>
  <div class="SignupStep1">
    <div class="title has-text-centered p-v-l is-4">
      {{ $t(`pages.onboarding.step_1.cover_text`) }}
    </div>

    <form @submit.prevent="proceed">
      sdadas
    </form>
    <div class="has-text-right OnboardingStep__actions">
      <v-button
        class="is-primary is-width-1/2-mobile"
        data-testid="SingupStep1_NextButton"
        @click="proceed"
      >
        {{ $t('general.next') }}
      </v-button>
    </div>
  </div>
</template>

<script>
import OnboardingStepPrototype, { createComputedFormField } from '@/components/onboarding/OnboardingStepPrototype'

/**
 * @module SignupStep1
 */
export default {
  name: 'SignupStep1',
  extends: OnboardingStepPrototype,
  computed: {
    first_name: createComputedFormField('first_name'),
    last_name: createComputedFormField('last_name'),
    email: createComputedFormField('email'),
    country_iso2: createComputedFormField('country_iso2'),
    brand_name: createComputedFormField('brand_name')
  }
}
</script>
